import React, { useEffect, useState } from 'react'

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { userAtom } from 'models/user/user-store'
import { FilePond, registerPlugin } from 'react-filepond'
import { useRecoilState } from 'recoil'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
)

interface FileUploaderProps {
  mediaType: 'photo' | 'video'
  mediaGroup: number | string
  onSuccess: any
  embededReportStatus?: '1' | '2' | '3' | '4' | '5'
  inspectionId?: number
}

const FileUploader: React.FunctionComponent<FileUploaderProps> = ({
  mediaType = 'photo',
  mediaGroup,
  onSuccess,
  embededReportStatus,
  inspectionId,
}) => {
  const [userData] = useRecoilState(userAtom)
  const [files, setFiles] = useState([])
  const [currentItem, setCurrentItem] = useState<any>({})
  const [uploadCount, setUploadCount] = useState<number>(0)

  useEffect(() => {
    const pageParams = new URLSearchParams(location.search)
    console.log('inspection id', inspectionId)
    const inspection_id = pageParams.get('inspection_id') || inspectionId
    let item_id: any = pageParams.get('item_id')
    const report_status = pageParams.get('report_status')
    const rs = {
      inline: '1',
      final: '2',
      refinal: '3',
      refinal2: '4',
      refinal3: '5',
    }

    let used_report_status: '1' | '2' | '3' | '4' | '5' = '1'
    if (!report_status) {
      used_report_status = embededReportStatus || '1'
    } else {
      used_report_status = rs[report_status]
    }

    setCurrentItem({
      inspection_id,
      inspection_item_id: item_id,
      report_status: used_report_status,
    })
  }, [])

  return (
    <FilePond
      files={files}
      // onupdatefiles={setFiles}
      allowMultiple={true}
      // acceptedFileTypes={['mp4']}
      maxFiles={100}
      // chunkUploads={true}
      allowImageExifOrientation={true}
      onprocessfiles={() => {
        onSuccess()
      }}
      maxParallelUploads={1}
      imagePreviewMaxHeight={100}
      onprocessfile={(error, file) => {
        // console.log('upload:', error)
        if (!error) {
          console.log('upload:', file)
          // onSuccess()
        } else {
          console.log('upload:', error)
        }
      }}
      onprocessfileabort={(file) => {
        console.log('upload:abort', file)
      }}
      onerror={(error, file) => {
        console.log('upload:x-', error)
      }}
      onprocessfileprogress={(file, progress) => {
        // console.log('upload:p-', progress)
      }}
      onupdatefiles={(files) => {
        console.log('upload:', files)
      }}
      server={{
        url: process.env.API_URL + 'upload' + mediaType,
        // headers: {
        //   'x-access-token': userData.token,
        // },
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options,
        ) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          const formData = new FormData()
          formData.append(fieldName, file, file.name)
          formData.append(
            'photo_type',
            embededReportStatus
              ? 'photo_inspection_page'
              : 'photo_item_inspection',
          )
          formData.append('type_upload', mediaGroup as string)
          formData.append('inspection_id', currentItem.inspection_id)
          if (currentItem.inspection_item_id) {
            formData.append(
              'inspection_item_id',
              currentItem.inspection_item_id,
            )
          }
          formData.append('report_status', currentItem.report_status)
          formData.append('sort_index', '1000')

          const request = new XMLHttpRequest()
          request.open('POST', process.env.API_URL + 'upload' + mediaType)
          request.setRequestHeader('x-access-token', userData.token)

          // Should call the progress method to update the progress to 100% before calling load
          // Setting computable to false switches the loading indicator to infinite mode
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }
          request.onerror = (event) => {
            // console.log('upload:e-', event)
            request.open('POST', process.env.API_URL + 'upload' + mediaType)
            request.setRequestHeader('x-access-token', userData.token)
            request.send(formData)
          }

          // Should call the load method when done and pass the returned server file id
          // this server file id is then used later on when reverting or restoring a file
          // so your server knows which file to return without exposing that info to the client
          request.onload = function (event) {
            if (request.status >= 200 && request.status < 300) {
              // the load method accepts either a string (id) or an object
              load(request.responseText)
            } else {
              // Can call the error method if something is wrong, should exit after
              console.log('upload: error')
              load(request.responseText)
              error('oh no')
            }
          }

          request.send(formData)

          // request.onerror = (event) => {
          //   console.log('event:', event)
          //   request.abort()
          // }

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              console.log('abort:')
              // This function is entered if the user has tapped the cancel button
              request.abort()

              // Let FilePond know the request has been cancelled
              abort()
            },
          }
        },
      }}
      name="files[]"
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
    />
  )
}

export default FileUploader
